export const fengSettings = {
  name: 'stcruzeiro',
  version: '0.0.1',
  hmr: true,
  projectName: 'CRUZEIRO ESPORTE CLUBE',
  clientName: 'CRUZEIRO ESPORTE CLUBE',
  frontUrl: 'Sócio 5 Estrelas',
  clientId: '',
  clientPhone: '(21) 2222-2222',
  clientEmail: 'socio5estrelas@cruzeiro.com.br',
  clientAddress: 'SEDE ADMINISTRATIVA: Rua dos Guajajaras, 1722 - BARRO PRETO - BELO HORIZONTE/MG',
  cache: {
    strategy: 'explicit',
    localStorageKey: 'fengHttpCache',
    ttl: 60000 // 1min (60000) - 5min (300000) - 30min (1800000) - 30min (1800000) - 1h (3600000) - 2h (7200000) - 6h (21600000) - 12h (43200000) - 24h (86400000)
  },
  auth: {
    enableSt: true,
    enableResale: false,
    enableCaptcha: true,
    verifyCPF: true,
    verifyEmail: true,
    keepConnected: true,
    loginForm: {
      title: 'Para primeiro acesso ao novo sistema, clique abaixo em "Gerar nova senha" para receber no seu e-mail cadastrado',
      text: 'Email/CPF',
      forgotPass: 'Gerar nova senha',
      forgotPassText: 'Preencha com o seu e-mail cadastrado no Sócio 5 Estrelas e enviaremos um link de recuperação para você. O e-mail só será enviado caso o cadastro seja encontrado.',
      forgotEmailText: 'Caso você tenha esquecido o seu e-mail, entre em contato com o atendimento <a href="/atendimento">clicando aqui</a>.'
    },
    firstAccess: {
      enable: false,
      ask: {
        password: false,
        terms: true,
        missingData: false,
        termChange: true
      }
    },
    social: [
        { name: 'google', enabled: false },
        { name: 'facebook', enabled: false }
    ],
    register: {
      enableDdi: false,
      enableSt: true,
      showTerms: true,
      fields: {
        genre: false
      },
      enableNonGenderSpecification: true,
      enableForeignForm: true
    },
    staging: {
      enable: false,
      title: 'SEJA BEM-VINDO!',
      subtitle: 'faça parte da torcida que conduz o time',
      description: `
        <p>
          Bloqueio de navegação para ambiente de staging <strong>em fase de teste</strong>.
        </p>
        <p>
          Para liberar navegação, adicione <strong>/login</strong> no final da URL desta página
          e efetue o login com um <strong>usuário administrativo</strong> com
          <strong>perfil 'Administrador', 'Desenvolvedor' ou 'Staging'.
        </p>
        <p>
          <strong>Após validação da funcionalidade, esta mensagem será removida.</strong>
        </p>
      `,
    },
    accessFlowRoutes: ['/ingressos'],
  },
  payment: {
    creditCard : {
      maxAllowed: 3,
      allowedTypes: [
        { type: 'visa', regex: new RegExp('^4') },
        { type: 'mastercard', regex: new RegExp('^5[1-5]') },
        { type: 'american-express', regex: new RegExp('^3[47]') },
        { type: 'diners', regex: new RegExp('^30[0-5]') },
        { type: 'jcb', regex: new RegExp('^35(2[89]|[3-8][0-9])') },
        { type: 'visa-electron', regex: new RegExp('^(4026|417500|4508|4844|491(3|7))') },
        { type: 'maestro',regex: new RegExp('^(5000|5018|5020|5038|6304|6759|676[1-3])') },
        { type: 'discover',regex: new RegExp('^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)') },
        { type: 'elo',regex: new RegExp('^(401178|401179|431274|438935|451416|457393|457631|457632|504175|627780|636297|636368|655000|655001|651652|651653|651654|650485|650486|650487|650488|506699|5067[0-6][0-9]|50677[0-8]|509\d{3})') },
        { type: 'hipercard',regex: new RegExp('^(606282\d{10}(\d{3})?|3841\d{15})') },
      ],
      enableCaptcha: true,
      enableDelete: true
    },
    installments: {
      enableInstallments: false,
      multiplePayment: {
        creditCardInstallmentsAllowed: false,
        bankSlipAllowed: true
      }
    },
  },
  loading: {
    global: 'ring', // default | ring | orbital | pulse
    local: 'bullets' // default | bars | bullets | progress
  },
  homeSettings: {
    scroll: {
      callToAction: {
        enable: true,
        template: 4,
        titleImage: 'https://cruzeiro.s3.amazonaws.com/prod/images/cta-title.png'
      },
      bannerUnlogged: true,
      hero: true,
      tabs: {
        enable: true,
        template: 5
      },
      plans: {
        enable: true,
        enableColorTheme: true
      },
      specialPlans: {
        enabled: true,
        idplano_group: ['4017a6e9d7c32689ea67c52c97edd5b4']
      },
      exclContent: false,
      testimony: false,
      callToActionFooter: false
    },
    cards: {
      enable: true,
      contentBox: true,
      experiences: true,
      plans: true,
      ticketCards: true,
      tickets: true,
      enableColorTheme: true
    },
    header: {
      legacyMsg: ``,
      templateHeader: 2,
      activeNewPlan: false,
      enableBenefitSearch: true,
      maxAnswers: 1,
      minAnswers: 1
    }
  },
  exclusiveContent: {
    enablePhotos: true
  },
  guardKidsPlan : [
    "KIDS",
    "KIDS*",
    "SOCIO INTERNACIONAL KIDS "
  ],
  myAccount: {
    home: {
      showBannerNewLink: true
    },
    menu: {
      plan: true,
      profile: true,
      payment: true,
      dependents: true
    },
    enableColorTheme: true,
    plan: {
      enableColorTheme: true,
      ticketCard: {
        showImage: false,
        enableStarRating: true,
        newCardRating: true,
        showRating: true,
      }
    },
    profile: {
      showDdiSelect: false,
      complementary: {
        enabled: true,
        fields: {
          nationality: true,
          rg: true,
          civilStatus: true,
          education: true,
          residentialPhone: true,
          shirtSize: true,
          shortSize: true,
          shoeSize: true,
          income: false,
          interest: false,
          matchFrequency: true,
          hasChildren: true,
          childrenNumber: true,
          profession: false,
          company: false,
          interestWorkField: false,
          club: false,
          hasCaptiveChair: true,
        },
        options: {
          shirtSize: [
            { value: 'P', label: 'P'},
            { value: 'M', label: 'M'},
            { value: 'G', label: 'G'},
            { value: 'GG', label: 'GG'},
            { value: '3G', label: '3G'},
            { value: '4G', label: '4G'},
            { value: '7G', label: '7G'},
          ]
        }
      },
      timeZone: "America/Bahia",
    },
    address: {
      addressNumberModifier: true,
      disabledCountry: true,
      outsideComponent: true
    },
    ticketCards: {
      textWarn: {
        enableForm: true,
        textInfo: `<strong>ATENÇÃO</strong> - Ative sua carteirinha clicando no botão abaixo <strong>apenas</strong> se já tiver com ela em mãos para usufruir dos benefícios`
      },
      tracking: {
        enabled: true,
        company: 'TMT'
      }
    },
    socialNetworks: {
      enabled: true
    },
    dependents: {
      template: 1,
      enableRegister: true,
      onlyShowInfo: true,
      title: 'MEUS KIDS',
      headerName: 'KIDS',
      showMessage: 'Você não possui Kids.',
      contact: `<p>Para atualizar, incluir ou editar os dados dos Kids, entre em contato com o atendimento de segunda a sexta, de 08:00 às 18:00 <br> através do 3140002161 - telefone e WhatsApp ou envie um e-mail para <a href="mailto:socio5estrelas@cruzeiro.com.br">socio5estrelas@cruzeiro.com.br</a>.</p>`,
      removeButtonAndText: true,
      noDependentsText: 'Não existem sócios kids ativos vinculados a esse cadastro.',
      rulesText: `<h3>REGRAS DE UTILIZAÇÂO</h3>
      <ul>
          <li><p>Trata-se de um Opcional, a título oneroso, em que cada titular poderá vincular no máximo 03 (três) crianças, menores de 12 (doze) anos, mediante análise de dados e envio da documentação solicitada pelo Cruzeiro SAF.</p></li>

          <li><p>A mensalidade correspondente a esta opção corresponde à R$ 10,00 (dez Reais).</p></li>

          <li><p>A solicitação de inclusão deve ser realizada exclusivamente por meio dos Canais Oficiais do Clube. Aprovada a solicitação de adesão, as crianças terão direito a ingressar, sem custos adicionais, no mesmo setor do titular responsável, mediante disponibilidade e aquisição de ingresso pelo respectivo titular, diretamente através de sua conta.</p></li>

          <li><p>O ingresso do kids deverá ser resgatado exclusivamente no mesmo setor do responsável vinculado a ele e pela conta do titular.</p></li>

          <li><p>O ingresso do kids só poderá ser validado caso o responsável já tenha ingresso ou que a compra seja feita de forma simultânea.</p></li>

          <li><p>Após a validação do ingresso do kids, caso o ingresso do responsável seja cancelado, o ingresso do kids também será cancelado.</p></li>

          <li><p>Em hipótese alguma o kids pode ter ingresso e seu responsável não.</p></li>

          <li><p>Este Opcional poderá ser adquirido por Sócios dos Planos: Meu Cruzeiro, Time do Povo, Cabuloso e Cabuloso Max, mediante disponibilidade.</p></li>

          <li><p>O uso indevido deste Opcional por terceiros poderá implicar a suspensão imediata do benefício.</p></li>

          <li><p>Este Opcional poderá ser cancelado</p>
            <ol type="I">
              <li>mediante solicitação do Usuário;</li>
              <li>utilização indevida do benefício;</li>
              <li>encerramento/cancelamento do Plano contratado.</li>
            </ol>
          </li>
          <li><p>O cancelamento do Opcional pode ser solicitado a qualquer momento, e segue a mesma regra do cancelamento de Planos, prevista no Regulamento, inclusive no que diz respeito às indenizações, quando se tratar de Opcional a título oneroso e deve ser solicitado diretamente nos canais oficiais de atendimento (3140002161 - telefone e WhatsApp). Não será permitida suspensão temporária.</p></li>
          <li><p>Uma vez cancelado o Opcional, caso o Usuário tenha interesse em retomar o benefício, deverá realizar novo processo de adesão e verificação.</p></li>
          </ul>`,
    },
    payment: {
      enableInverseOrderInstallments: true
    }
  },
  partnersSettings: {
    enableMoreInfo: false,
    enableColorTheme: true,
  },
  experiencesSettings: {
    menu: {
      catalog: true,
      howToScore: true,
      points: true,
      rescued: true
    },
    points: {
      enabledExperiences: false,
      enabledLoyalty: false,
      enabledFilterCategories: false
    },
    embassies: {
      enableFilter: false
    },
    enableSocioClub: false,
    queueRetryLimit: 12
  },
  faqSettings: {
    faqTemplate: 4,
    mockFaq: false,
    enableColorTheme: true,
    contacts: {
      chat: {
        active: true,
        availability: 'de segunda a sexta, das 8h às 21h, e aos sábados, das 10h às 16h',
      },
      email: {
        active: true
      },
      types: {
        'Cartão': ['Dificuldade de ativação', 'Perda do Cartão', 'Não recebimento do cartão', 'Erro na catraca', '2ª via do Cartão'],
        'Ingresso': ['Ingresso para o próximo jogo', 'Pagamento do Ingresso', 'CPF inválido', 'Mudança de Mídia', 'Cancelamento do Ingresso', 'Ingresso Kids'],
        'Financeiro': ['Upgrade', 'Estorno', 'Estorno de Cancelamento', 'Pagamento do Plano', 'Boleto do Plano'],
        'Adesão': ['Sócio Kids', 'Sócio Internacional', 'Sócio Eficiente', 'Auxílio na Adesão'],
        'Cancelamento': ['Cancelamento de Upgrade', 'Cancelamento do Plano', 'Cancelamento 7 dias'],
        'Dúvidas Gerais': ['Informações', 'Login', 'Aplicativo', 'Alteração de Cadastro'],
        'Spam': ['Spam'],
        'Reclame Aqui': ['Reclame aqui'],
        'Programa de Pontos': ['Dúvidas sobre pontuação', 'Envio do item resgatado', 'Mascotinho', 'Resgate presencial'],
        'Renovação': ['Retirar renovação automática', 'Renovação', 'Dúvidas sobre renovação', 'Antecipação de Renovação'],
        'Contrato Bloqueado': ['Bloqueado por Chargeback'],
        'Reclamação': ['Torcedor não se sente ouvido', 'Decisões da SAF', 'Insatisfação com o clube - Geral', 'Inclusão do 13° mês'],
        'Parou de interagir': ['Parou de interagir'],
        'Zé Delivery': ['Zé Delivery']
      },
    }
  },
  headerSettings: {
    headerTemplate: 7,
    // hideSecondaryMenu: true,
    floatingLogin: false,
    enableCounter: true,
    enableMobileMenu: true,
    nav: [
      { title: 'HOME', routerLink: '/home', routerLinkActiveOptions:  { exact: true } },
      { title: 'MINHA CONTA', routerLink: '/minha-conta/meu-plano', routerLinkActiveOptions: { exact: false } },
      // { title: 'PAGAMENTOS', routerLink: '/minha-conta/pagamentos', routerLinkActiveOptions: { exact: false } },
      { title: 'PLANOS', routerLink: '/planos', routerLinkActiveOptions: { exact: true } },
      // { title: 'PARCEIROS', routerLink: '/parceiros', routerLinkActiveOptions: { exact: true } },
      // { title: 'EXPERIÊNCIAS', routerLink: '/experiencias', routerLinkActiveOptions: { exact: false } },
      // { title: 'CONTEÚDO EXCLUSIVO', routerLink: '/conteudo-exclusivo', routerLinkActiveOptions: { exact: true } },
      // { title: 'INGRESSOS', routerLink: '/ingressos', routerLinkActiveOptions: { exact: true } },
      // { title: 'TRANSPARÊNCIA', routerLink: '/transparencia', routerLinkActiveOptions: { exact: true } },
      // { title: 'ATENDIMENTO', routerLink: '/atendimento', routerLinkActiveOptions: { exact: true } },
    ],
    unloggedNav: [
    ],
    hidePoints: true,
     social: [
      { icon: 'instagram', href: 'https://www.instagram.com/cruzeiro' },
      { icon: 'x', href: 'https://twitter.com/cruzeiro' },
      { icon: 'youtube', href: 'https://www.youtube.com/user/cruzeiro' },
      { icon: 'tiktok', href: 'https://www.tiktok.com/@cruzeiro' },
      { icon: 'facebook', href: 'https://www.facebook.com/Cruzeiro' }
    ],
  },
  footerSettings: {
    footerTemplate: 3,
    social: [
      { icon: 'twitter', href: 'https://twitter.com/cruzeiro' },
      { icon: 'instagram', href: 'https://www.instagram.com/cruzeiro' },
      { icon: 'youtube', href: 'https://www.youtube.com/user/cruzeiro' },
      { icon: 'facebook', href: 'https://www.facebook.com/Cruzeiro' }
    ],
    nav: [
      //{ title: 'HOME', routerLink: '/', click: '', clickParam: '', if: 'notHome' },
      { title: 'PLANOS', routerLink: '/planos', click: '', clickParam: '', if: '' },
      //{ title: 'TRANSPARÊNCIA', routerLink: '/transparencia', click: '', clickParam: '', if: '' },
      //{ title: 'TERMOS DE USO', routerLink: '/termos-e-politicas', click: '', clickParam: '', if: '' },
      //{ title: 'FALE CONOSCO', routerLink: '/atendimento', click: '', clickParam: '', if: '' },
      { title: 'ASSOCIE-SE', routerLink: '/checkout/cart', click: '', clickParam: '', if: '' },
    ],
    partners: [
      {
        line: [
          {title: 'adidas', img: 'https://cruzeiro.s3.amazonaws.com/prod/assets/images/parceiro-1.png', href: 'https://www.adidas.com.br/cruzeiro'},
          {title: 'betfair', img: 'https://cruzeiro.s3.amazonaws.com/prod/assets/images/parceiro-2.png', href: 'https://apostas.betfair.com/palpites-cruzeiro/'},
          {title: 'supermercados bh', img: 'https://cruzeiro.s3.amazonaws.com/prod/assets/images/parceiro-3.png', href: 'https://www.supermercadosbh.com.br/belo-horizonte/'}
        ]
      },
      {
        line: [
          {title: 'brahma', img: 'https://cruzeiro.s3.amazonaws.com/prod/assets/images/parceiro-4.png', href: 'https://www.brahma.com.br/pt-br'},
          {title: 'cimed', img: 'https://cruzeiro.s3.amazonaws.com/prod/assets/images/parceiro-5.png', href: 'https://www.comprecimed.com.br/'},
          {title: 'gerdau', img: 'https://cruzeiro.s3.amazonaws.com/prod/assets/images/parceiro-6.png', href: 'http://www.gerdau.com.br/'},
          {title: 'mm aluguel de carros', img: 'https://cruzeiro.s3.amazonaws.com/prod/assets/images/parceiro-7.png', href: 'https://mmalugueldecarros.com/'},
          {title: 'multivix', img: 'https://cruzeiro.s3.amazonaws.com/prod/assets/images/parceiro-8.png', href: 'https://multivix.edu.br/'},
        ]
      },
      {
        line: [
          {title: 'cemig sim', img: 'https://cruzeiro.s3.amazonaws.com/prod/assets/images/parceiro-9.png', href: '/'},
          {title: 'mws', img: 'https://cruzeiro.s3.amazonaws.com/prod/assets/images/parceiro-10.png', href: '/'},
          {title: 'slides', img: 'https://cruzeiro.s3.amazonaws.com/prod/assets/images/parceiro-11.png', href: '/'},
          {title: 'tnt drink', img: 'https://cruzeiro.s3.amazonaws.com/prod/assets/images/parceiro-12.png', href: '/'},
          {title: 'stats perform', img: 'https://cruzeiro.s3.amazonaws.com/prod/assets/images/parceiro-13.png', href: '/'},
        ]
      }
    ],
    enableTerms: true
  },
  plans: {
    template: 4,
    cardPlaceholder: "https://cruzeiro.s3.amazonaws.com/prod/assets/images/cartao-socio-torcedor.png",
    enableDependents: false,
    enableInternationalPlans: true,
    enableSpecialPlan: false,
    subscribeText: 'QUERO ESTE!',
    plansWithBlockedInvites: ['DIAMANTE', 'DIAMANTE*'],
    idPlanKids: '2de77cdefda87d8b5dfa62d3e61d083d',
    activationState: ['201', '202', '203'],
    onlyRenewToTheSamePlan: [
      'TIME DO POVO'
    ],
  },
  plansOutside: {
    template: 1,
    cardPlaceholder: "https://cruzeiro.s3.amazonaws.com/prod/assets/images/cartao-socio-torcedor.png",
    subscribeText: 'QUERO ESTE!'
  },
  plansIntent: {
    template: 1,
    cardPlaceholder: "https://cruzeiro.s3.amazonaws.com/prod/assets/images/cartao-socio-torcedor.png",
    title: 'MAIS CRUZEIRO, EM TODOS OS JOGOS',
    description: 'PLANOS PARA QUEM QUER ESTAR SEMPRE NOS MELHORES LUGARES DO MINEIRÃO, COM TRATAMENTO ESPECIAL',
    subscribeText: 'QUERO ESTE!',
    route: 'diamante',
  },
  specialPlan: {
    template: 2,
  },
  xRay: {
    featuredTemplate: 3,
    financialTemplate: 3,
    enableCounter: true,
    enableTabBackgroundColor: true,
    enableStates: true,
    enableCities: true,
    enableDistricts: true,
    enablePlans: true,
    enablePaymentMethod: true,
    enableAge: true,
    enableSex: true,
    enableXRay: true,
    enableXRayActionBackgroundColor: true,
    enableFinancial: false,
    enableTransparency: false,
    tranparencyMonthsNameType: 'fullName', // fullName | shortName,
    colorThemeToolTip: true
  },
  embassies: {
    template: 2
  },
  checkout: {
    defaultDuration: '12',
    maxGuests: false,
    showPaymentMethods: true,
    comingSoonPaymentSlip: false,
    disableRequestResponsible: true,
    showAddOns: true,
    renewWithContract: true,
    modalSixPeriodicity: {
      enabled: false,
      title: 'ATENÇÃO',
      message: `
        Os planos com duração semestral não possuem o benefício do kit de boas-vindas.
        Você receberá apenas a carteirinha. Caso deseje receber o kit, altere a periodicidade para Anual.
      `
    },
    disableSteloMessage: true,
    offState: {
      state: 'RJ',
      modalOffState: {
        enabled: false,
        title: 'ATENÇÃO',
        message: `
          Você selecionou um plano fora do estado do Rio de Janeiro, iremos te redirecionar para página de planos para selecionar o plano correto.
        `
      }
    },
    offCity: {
      city: 'Belo Horizonte',
      districts: [
        'BALDIM',
        'BARAO DE COCAIS',
        'BELO HORIZONTE',
        'BELO VALE',
        'BETIM',
        'BOM JESUS DO AMPARO',
        'BONFIM',
        'BRUMADINHO',
        'CAETE',
        'CAPIM BRANCO',
        'CONFINS',
        'CONTAGEM',
        'ESMERALDAS',
        'FLORESTAL',
        'FORTUNA DE MINAS',
        'FUNILANDIA',
        'IBIRITE',
        'IGARAPE',
        'INHAUMA',
        'ITABIRITO',
        'ITAGUARA',
        'ITATIAIUÇU',
        'ITAUNA',
        'JABOTICATUBAS',
        'JUATUBA',
        'LAGOA SANTA',
        'MARIO CAMPOS',
        'MATEUS LEME',
        'MATOZINHOS',
        'MOEDA',
        'NOVA LIMA',
        'NOVA UNIAO',
        'PARA DE MINAS',
        'PEDRO LEOPOLDO',
        'PRUDENTE DE MORAIS',
        'RAPOSOS',
        'RIBEIRAO DAS NEVES',
        'RIO ACIMA',
        'RIO MANSO',
        'SABARA',
        'SANTA BARBARA',
        'SANTA LUZIA',
        'SAO GONÇALO DO RIO ABAIXO',
        'SAO JOAQUIM DE BICAS',
        'SAO JOSE DA LAPA',
        'SAO JOSE DA VARGINHA',
        'SARZEDO',
        'SETE LAGOAS',
        'TAQUARAÇU DE MINAS',
        'VESPASIANO'
      ],
      modalOffCity: {
        enabled: true,
        title: 'ATENÇÃO',
        message: `
          Você selecionou um plano fora da grande Belo Horizonte, iremos te direcionar para a página de planos para selecionar o plano correto.
        `
      }
    },
    successPage: {
      enabled: true,
      title: 'Pagamento processado!',
      message: `Seja bem vindo ao Sócio Torcedor Cruzeiro.`,
      templateCustomize: 1
    },
    successOutsidePage: {
      // enabled: true,
      title: 'SEJA MUITO BEM-VINDO(A) AO',
      message: '',
      warning: 'Em até 3 dias você receberá uma fatura da PayPal no e-mail cadastrado para pagamento e confirmação do plano escolhido',
      templateCustomize: 1
    },
    successIntentPage: {
      // enabled: true,
      title: 'SEJA MUITO BEM-VINDO(A) AO',
      message: '',
      warning: 'Em até 3 dias nosso Atendimento entrará em contato por telefone ou e-mail para finalizar a adesão do seu plano.',
      template: 1
    },
    boletoPage: {
      enabled: true,
      title: 'Boleto gerado com sucesso!',
      message: `Agora falta pouco! Pague o seu boleto até a data de vencimento.`
    },
    paymentFailPage: {
      enabled: true,
      title: 'Ocorreu um erro ao efetuar o seu pagamento!',
      message: `Não foi possível processar o seu pagamento na forma de pagamento selecionada.`
    },
    enableValidator: true,
    addonsPerPlan: {
      // '8988a5b838f06397c0121a38777696b3': [ //? SOU CRUZEIRO
      //   'Kids',
      //   'Internacional',
      // ],
      '58b3ad47489d75e18feddafb6f5bf1c5': [ //? NAÇÃO AZUL
        'Carteirinha',
        // 'Kids',
        // 'Internacional',
      ],
      // '0c5911deb79cc7025c4494440958a87d': [ //? CABULOSO
      //   'Kids',
      //   'Internacional',
      //   'PCD',
      //   'Cativa',
      // ],
      // '0e791dd92788b2d00d3306188e52fa12': [ //? CABULOSO MAX
      //     'Kids',
      //     'Internacional',
      //     'Cativa',
      // ],
    },
    register: {
      address: {
        disableForeign: true
      }
    }
  },
  events: {
    shield: "https://cruzeiro.s3.amazonaws.com/prod/assets/images/escudo-cruzeiro.svg",
    background: "https://cruzeiro.s3.amazonaws.com/prod/assets/images/bg-home-deslogada2.png",
    name: "CRUZEIRO",
    hideBuyTicketForPlans:['eb42e5d9baffe8b39c49f1f83a1dee79', '39f936ca93a80c57297eef0fc5fc62e9'],
    template: 1,
    ticketing: 'FCARD', // Available Options: FCARD / MBILHETE / TICKETHUB / SUPERINGRESSO
    showMyTickets: true,
    hasWaves: true,
    integration: {
      enableTicketBalance: true
    },
    openIntegrationSameTab: true,
    redirectTime: 3,
    showClubLink: true,
    clubLink: 'ingresso.cruzeiro.com.br'
  },
  terms: {
    splitted: true,
    termsURL: {
      url: null,
      route: '/termos-e-politicas',
      pageTitle: null,
      contentKeywords: ['termos', 'uso'],
    },
    privacyURL: {
      url: null,
      route: '/politica-de-privacidade',
      pageTitle: null,
      contentKeywords: ['politica', 'privacidade']
    },
    caravanInfoURL: {
      url: null,
      route: '/caravana/informativo',
      contentKeywords: ['caravana', 'informativo'],
    },
  },
  landing: {
    template: 1,
    promptValidation: {
      cpf: true,
      email: true,
    },
    header: {
      logo: 'https://cruzeiro.s3.amazonaws.com/prod/images/landing/caravana/logo_desk.png',
      logoMob: 'https://cruzeiro.s3.amazonaws.com/prod/images/landing/caravana/logo_mob.png',
      shield:'https://cruzeiro.s3.amazonaws.com/prod/assets/images/escudo-cruzeiro.svg'
    },
    nav: {
      registerRoute: '/caravana/cadastro',
      successRoute: '/caravana/sucesso',
      indicationRoute: '/caravana/indicacao',
      caravanInfoRoute: '/caravana/informativo',
    },
    caravanMoreInfo: {
      title: `O QUE VOCÊ VAI<br> <strong>APROVEITAR NA CARAVANA</strong>`,
      beforeFooter: `E MUITO MAIS!`,
      transmission: {
        img: 'https://cruzeiro.s3.amazonaws.com/prod/assets/images/ico-transmisaoaovivo.png',
        description: `
        TRANSMISSÃO DE <strong>JOGO</strong><br>
        `
      },
      cup: {
        img: 'https://cruzeiro.s3.amazonaws.com/prod/assets/images/caravanas-icon-cup.png',
        description: `
        MUSEU<br> <strong>CABULOSO</strong>
        `
      },
      user: {
        img: 'https://cruzeiro.s3.amazonaws.com/prod/assets/images/caravanas-icon-user.png',
        description: `
        PRESENÇA<br> DE <strong>ÍDOLOS</strong>
        `
      },
      cart: {
        img: 'https://cruzeiro.s3.amazonaws.com/prod/assets/images/caravanas-icon-cart.png',
        description: `
        <strong>LOJA OFICIAL</strong><br> DO CLUBE
        `
      },
      card: {
        img: 'https://cruzeiro.s3.amazonaws.com/prod/assets/images/caravanas-icon-card.png',
        description: `
        VENDA EXCLUSIVA DE <strong>SÓCIO</strong><br> 5 ESTRELAS<strong></strong>
        `
      },
      gift: {
        img: 'https://cruzeiro.s3.amazonaws.com/prod/images/landing/caravana/caravanas-gift-icon.png',
        description: `
        <strong>BRINDES</strong><br> ESPECIAIS
        `
      }
    },
    caravanSponsors: {
      desktopImg: 'https://cruzeiro.s3.amazonaws.com/prod/images/landing/caravana/sponsors-desk.png',
      mobileImg: 'https://cruzeiro.s3.amazonaws.com/prod/images/landing/caravana/sponsors-mob.png',
    },

    description: '<p>Para você aproveitar as experiências exclusivas da Caravana do Cruzeiro é necessário o preenchimento do cadastro abaixo. Contamos com você!</p>',
    warning: '<p>* CASO VOCÊ JÁ TENHA CADASTRO NO SÓCIO 5 ESTRELAS, VOCÊ NÃO PRECISA PROSSEGUIR POR AQUI, VOCÊ JÁ TEM ACESSO GARANTIDO A CARAVANA!</p>',
    // video: {
    //   url: 'https://www.youtube.com/embed/Cj-qsebYtHg?autoplay=1&mute=1&loop=1&playlist=Cj-qsebYtHg&showinfo=0'
    // },
  },
  contact: {
    formRecipient: [
      { name: 'email', active: false },
      { name: 'salesForceCase', active: true },
    ],
  },
  konduto: {
    active: false
  }

}
