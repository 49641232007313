import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard, GuestGuard, GuardianGuard, MaintenanceGuard, NotMaintenanceGuard, NotStagingGuard, StagingGuard, BenefitResearchGuard, PendingEmailGuard, PendingGuard, PhoneGuard} from '@fengbrasil/ngx-fengst-auth';
import { MaintenanceComponent, PageNotFoundComponent, InternalServerErrorComponent, LogoutComponent, TermsComponent, RecoveryPasswordComponent, StagingComponent, ScailingMessageComponent, DiscountValidatorComponent, PrivacyComponent } from '@fengbrasil/ngx-fengst-layout';
import { InternationalSurveyComponent } from './international-survey/international-survey.component';
import { PendingAddressGuard } from '@fengbrasil/ngx-fengst-checkout';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-home').then((m) => m.HomePublicModule),
    canActivate: [StagingGuard, GuestGuard],
  },
  {
    path: 'home',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-home').then((m) => m.HomePrivateModule),
    canActivate: [StagingGuard, AuthGuard, MaintenanceGuard, GuardianGuard, PhoneGuard],
  },
  {
    path: 'home/:page',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-home').then((m) => m.HomePublicModule),
    canActivate: [StagingGuard, MaintenanceGuard],
  },
  {
    path: 'minha-conta/meu-plano',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-account').then((m) => m.PlanModule),
    canActivate: [StagingGuard, MaintenanceGuard, AuthGuard, PendingEmailGuard, GuardianGuard, PendingGuard, PhoneGuard],
  },
  {
    path: 'minha-conta/meu-perfil',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-account').then((m) => m.ProfileModule),
    canActivate: [StagingGuard, MaintenanceGuard, AuthGuard, PendingEmailGuard, GuardianGuard, PendingGuard, PhoneGuard],
  },
  {
    path: 'minha-conta/pagamentos',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-account').then((m) => m.PaymentsModule),
    canActivate: [StagingGuard, MaintenanceGuard, AuthGuard, GuardianGuard],
  },
  {
    path: 'minha-conta/kids',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-account').then((m) => m.DependentsModule),
    canActivate: [StagingGuard, MaintenanceGuard, AuthGuard, GuardianGuard, ],
  },
  { 
    path: 'checkout/success', 
    loadChildren: () => import('@fengbrasil/ngx-fengst-checkout').then(m => m.SuccessModule),
    canActivate: [StagingGuard, MaintenanceGuard, AuthGuard]
  },
  { 
    path: 'checkout/success-outside', 
    loadChildren: () => import('@fengbrasil/ngx-fengst-checkout').then(m => m.SuccessOutsideModule),
    canActivate: [StagingGuard, MaintenanceGuard, AuthGuard]
  },
  { 
    path: 'checkout/success-intent', 
    loadChildren: () => import('@fengbrasil/ngx-fengst-checkout').then(m => m.SuccessIntentModule),
    canActivate: [StagingGuard, MaintenanceGuard, AuthGuard]
  },
  { 
    path: 'checkout/subscribe/:idplano', 
    loadChildren: () => import('@fengbrasil/ngx-fengst-checkout').then(m => m.SubscribeModule),
    canActivate: [StagingGuard, MaintenanceGuard, AuthGuard, PendingAddressGuard]
  },
  { 
    path: 'checkout/subscribe-intent/:idplano', 
    loadChildren: () => import('@fengbrasil/ngx-fengst-checkout').then(m => m.SubscribeModule),
    canActivate: [StagingGuard, MaintenanceGuard, AuthGuard, PendingAddressGuard]
  },
  { 
    path: 'checkout/subscribe-outside/:idplano', 
    loadChildren: () => import('@fengbrasil/ngx-fengst-checkout').then(m => m.SubscribeModule),
    canActivate: [StagingGuard, MaintenanceGuard, AuthGuard, PendingAddressGuard]
  },
  { 
    path: 'checkout/subscribe-intent/:idplano', 
    loadChildren: () => import('@fengbrasil/ngx-fengst-checkout').then(m => m.SubscribeModule),
    canActivate: [StagingGuard, MaintenanceGuard, AuthGuard, PendingAddressGuard]
  },
  { 
    path: 'checkout/resubscribe/:idplano', 
    loadChildren: () => import('@fengbrasil/ngx-fengst-checkout').then(m => m.ResubscribeModule),
    canActivate: [StagingGuard, MaintenanceGuard, AuthGuard, PendingAddressGuard]
  },
  { 
    path: 'checkout/resubscribe/:idplano/:idcontrato', 
    loadChildren: () => import('@fengbrasil/ngx-fengst-checkout').then(m => m.ResubscribeContractModule),
    canActivate: [MaintenanceGuard, AuthGuard, PendingAddressGuard]
  },
  { 
    path: 'checkout/upgrade/:idplano', 
    loadChildren: () => import('@fengbrasil/ngx-fengst-checkout').then(m => m.UpgradeModule),
    canActivate: [StagingGuard, MaintenanceGuard, AuthGuard, PendingAddressGuard]
  },
  { 
    path: 'checkout/address', 
    loadChildren: () => import('@fengbrasil/ngx-fengst-checkout').then(m => m.AddressModule),
    canActivate: [StagingGuard, MaintenanceGuard, AuthGuard]
  },
  { 
    path: 'checkout/address-outsider', 
    loadChildren: () => import('@fengbrasil/ngx-fengst-checkout').then(m => m.AddressOutsiderModule),
    canActivate: [MaintenanceGuard, AuthGuard]
  },
  {
    path: 'checkout/address-outsider-update', 
    loadChildren: () => import('@fengbrasil/ngx-fengst-checkout').then(m => m.AddressOutsiderUpdateModule),
    canActivate: [MaintenanceGuard, AuthGuard]
  },
  {
    path: 'checkout/cart-deps/:type/:idplano',
    loadChildren: () => import('@fengbrasil/ngx-fengst-checkout').then((m) => m.CartDepsModule),
    canActivate: [StagingGuard, MaintenanceGuard, PendingAddressGuard]
  },
  {
    path: 'checkout/deps/registration',
    loadChildren: () => import('@fengbrasil/ngx-fengst-checkout').then(m => m.RegistrationDepModule),
    canActivate: [StagingGuard, MaintenanceGuard, AuthGuard]
  },
  {
    path: 'checkout/deps/register',
    loadChildren: () => import('@fengbrasil/ngx-fengst-checkout').then(m => m.RegisterDepModule),
    canActivate: [StagingGuard, MaintenanceGuard, GuestGuard],
  },
  {
    path: 'checkout/deps/subscribe/:idplano',
    loadChildren: () => import('@fengbrasil/ngx-fengst-checkout').then(m => m.SubscribeDepsModule),
    canActivate: [StagingGuard, MaintenanceGuard, AuthGuard, PendingAddressGuard]
  },
  { 
    path: 'checkout/cart/:type/:idplano', 
    loadChildren: () => import('@fengbrasil/ngx-fengst-checkout').then(m => m.CartModule),
    canActivate: [StagingGuard, MaintenanceGuard, PendingAddressGuard]
  },
  { 
    path: 'checkout/cart', 
    loadChildren: () => import('@fengbrasil/ngx-fengst-checkout').then(m => m.CartModule),
    canActivate: [StagingGuard, MaintenanceGuard, PendingAddressGuard]
  },
  {
    path: 'planos',
    loadChildren: () => import('@fengbrasil/ngx-fengst-checkout').then(m => m.PlansModule),
    canActivate: [StagingGuard, MaintenanceGuard, PhoneGuard]
  },
  {
    path: 'carrinho',
    redirectTo: 'checkout/register',
    pathMatch: 'full'
  },
  {
    path: 'checkout/register',
    loadChildren: () => import('@fengbrasil/ngx-fengst-checkout').then(m => m.RegisterModule),
    canActivate: [StagingGuard, MaintenanceGuard, GuestGuard, ],
  },
  {
    path: 'login-app',
    loadChildren: () => import('@fengbrasil/ngx-fengst-auth').then(m => m.LoginAppModule),
    canActivate: [StagingGuard, MaintenanceGuard]
  },
  {
    path: 'chatbot/sso',
    loadChildren: () => import('@fengbrasil/ngx-fengst-auth').then(m => m.NgxFengSTChatbotSsoModule),
    canActivate: [StagingGuard, MaintenanceGuard]
  },
  {
    path: 'sso',
    loadChildren: () => import('@fengbrasil/ngx-fengst-auth').then(m => m.LoginAppModule),
    canActivate: [StagingGuard, MaintenanceGuard]
  },
  {
    path: 'caravana',
    loadChildren: () =>
    import('@fengbrasil/ngx-fengst-landing').then((m) => m.LandingMainModule),
      canActivate: [MaintenanceGuard],
  },
  {
    path: 'caravana/cadastro',
    loadChildren: () =>
    import('@fengbrasil/ngx-fengst-landing').then((m) => m.LandingRegisterModule),
      canActivate: [MaintenanceGuard],
  },
  {
    path: 'caravana/indicacao',
    loadChildren: () =>
    import('@fengbrasil/ngx-fengst-landing').then((m) => m.LandingIndicationModule),
      canActivate: [MaintenanceGuard],
  },
  {
    path: 'caravana/sucesso',
    loadChildren: () =>
    import('@fengbrasil/ngx-fengst-landing').then((m) => m.LandingSuccessModule),
      canActivate: [MaintenanceGuard],
  },
  {
    path: 'caravana/informativo',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-landing').then((m) => m.LandingCaravanInfoModule),
    canActivate: [MaintenanceGuard],
  },
  {
    path: 'cadastrar-celular',
    loadChildren: () =>
      import('@fengbrasil/ngx-fengst-account').then((m) => m.PhoneModule),
    canActivate: [StagingGuard, MaintenanceGuard, AuthGuard],
  },
  {
    path: 'termos-e-politicas',
    component: TermsComponent,
    canActivate: [StagingGuard, MaintenanceGuard],
  },
  {
    path: 'politica-de-privacidade',
    component: PrivacyComponent,
    canActivate: [StagingGuard, MaintenanceGuard],
  },
  {
    path: 'staging',
    component: StagingComponent,
    canActivate: [NotStagingGuard],
  },
  {
    path: 'staging/login',
    component: StagingComponent,
    canActivate: [NotStagingGuard],
  },
  {
    path: 'consultar-socio',
    component: DiscountValidatorComponent,
    canActivate: [MaintenanceGuard],
  },
  {
    path: 'sair',
    component: LogoutComponent,
    canActivate: [StagingGuard, MaintenanceGuard],
  },
  {
    path: 'alta-demanda',
    component: ScailingMessageComponent
  },
  {
    path: '500',
    component: InternalServerErrorComponent
  },
  {
    path: 'manutencao',
    component: MaintenanceComponent,
    canActivate: [NotMaintenanceGuard],
  },
  {
    path: '**',
    component: PageNotFoundComponent,
    canActivate: [StagingGuard, MaintenanceGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }